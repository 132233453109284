import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import UserMenu from '../lib/components/UserMenu';
import {Layout} from 'antd';
import {Trans, withTranslation} from 'react-i18next';
import {inject, observer} from 'mobx-react';
import WestMenu from '../lib/components/WestMenu';
import menu from './west-menu-items.json';
import {routes} from '../lib/components/Auth';

const companyName = !'$REACT_APP_COMPANY_NAME'.endsWith('REACT_APP_COMPANY_NAME') ?
    '$REACT_APP_COMPANY_NAME' : process.env.REACT_APP_COMPANY_NAME || undefined;

const {
    Header, Content, Sider,
} = Layout;


class AppLayout extends Component {
    state = {
        collapsed: false,
    };

    onCollapse = (collapsed) => {
        this.setState({collapsed});
    };

    getActiveMenuKey = (path) => {
        const section = path.match(/[\w\-_]+/);
        if (!section) return null;
        return section[0];
    };

    render() {
        const {location, children, t, appStore} = this.props;

        if (!appStore.isReady) {
            return null;
        }

        const selectedKey = this.getActiveMenuKey(location.pathname),
            allowedKeys = routes.filter(r => appStore.checkInterfacePermission(r.permissions)).map(key => key.key);

        return (
            <Layout>
                <Sider
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={this.onCollapse}
                    width={this.state.collapsed ? 80 : 265}
                >
                    <div className="logo">
                        <Trans t={t} values={{
                            company_name: companyName,
                        }} i18nKey="logo">App title <div
                            className="logo-subtitle">subtitle</div></Trans>
                    </div>
                    <div className={'ant-menu-wrap'}>
                        <WestMenu
                            menu={menu}
                            allowedKeys={allowedKeys}
                            selectedKey={selectedKey}
                            t={t}
                        />
                    </div>
                </Sider>
                <Layout style={{marginLeft: this.state.collapsed ? 80 : 265}}>
                    <Header>
                        <div className="topmenu">
                            <UserMenu/>
                        </div>
                    </Header>
                    <Content>
                        {children}
                    </Content>
                </Layout>
            </Layout>
        );
    }
}

export default withRouter(withTranslation('common')(inject('appStore')(observer(AppLayout))));

