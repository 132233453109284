import { defaultParams, BaseReportStore } from '../../lib/stores/BaseReportStore';

export default class CallsStore extends BaseReportStore {
    method = 'get.calls'

    params = {
        ...defaultParams,
        filter: []
    }

}


