import BaseReportStore2 from '../../lib/stores/BaseReportStore2';

const defaultDataCenter = !'$REACT_APP_DEFAULT_DATACENTER'.endsWith('REACT_APP_DEFAULT_DATACENTER') ?
    '$REACT_APP_DEFAULT_DATACENTER' : process.env.REACT_APP_DEFAULT_DATACENTER || undefined;

export const defaultFilterParams = {
    app_id: undefined,
    search: undefined,
    tp_ids: undefined,
    states: 'waiting,active,manual_lock,limit_lock,debt_lock',
    data_centers: defaultDataCenter,
};

export default class ClientsStore extends BaseReportStore2 {
    constructor(props, limit) {
        super(props);

        this.method = 'get.apps';

        this.defaultReportParams = {
             filterParams: {...defaultFilterParams},
             tableParams: {
                limit,
                offset: 0,
                sort: [{field: 'app_id', order: 'desc'}]
            }
        };

        this.reportParams.filterParams = {...defaultFilterParams};

        this.defaultTablePagination.pageSize = limit;
        this.defaultTablePagination.pageSize = `${limit}`;

        const pageSizeOptions = [...this.defaultTablePagination.pageSizeOptions];
        !pageSizeOptions.includes(`${limit}`) && pageSizeOptions.push(`${limit}`);
        pageSizeOptions.sort((option1, option2) => option1 - option2);
        pageSizeOptions.sort((option1, option2) => option1 - option2);

        this.defaultTablePagination.pageSizeOptions = pageSizeOptions;

        this.tablePagination = {
            ...this.defaultTablePagination,
            pageSizeOptions: [...pageSizeOptions]
        };
    }

    getLoadParams() {
        return ['limit', 'offset'].reduce(
            (params, key) => ((params[key] = parseInt(params[key], 0) || 0), params),
            super.getLoadParams()
        );
    }
}
