import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { observer, inject } from 'mobx-react';

export const AuthProtectedRoute = inject('appStore')(observer(({ appStore, component: C, ...rest }) => {
    return <Route
        {...rest}
        render={props =>
            appStore.isAuthenticated
                ? <C {...props} />
                : <Redirect
                    to={'/login'}
                />}
    />;
}));

export const ProtectedRoute = inject('appStore')(observer(({ appStore, component: C, ...rest }) => {
    const route = rest.path.slice(1),
        availableRoutes = routes.filter(r => appStore.checkInterfacePermission(r.permissions));
    return <Route
        {...rest}
        render={props => (
            (availableRoutes.map(r => r.key).indexOf(route) !== -1)
                ? <C {...props} />
                : <Redirect
                    to={'/' + availableRoutes[0].key}
                />
        )}
    />;
}));

export const routes = [
    { key: 'clients', permissions: ['apps_management_apps'] },
    { key: 'app-trials', permissions: ['app_trials'] },
    { key: 'system-users', permissions: ['common_system_users'] },
    { key: 'app-users', permissions: ['app_users'] },
    { key: 'changeshistory', permissions: ['statistics_revision_history'] },
    { key: 'route/test', permissions: ['routing_test_routes'] },
    { key: 'checknumbers', permissions: ['check_numbers'] },
    { key: 'calls', permissions: ['statistics_call_report'] },
    { key: 'crm-integrations', permissions: ['apps_management_crm_integration'] },
    { key: 'telephony-history', permissions: ['statistics_telephony_history'] },
    { key: 'authorizationshistory', permissions: ['statistics_auth_history'] },
    { key: 'antirobot', permissions: ['antirobot'] },
    { key: 'unbindingnumbers', permissions: ['unbinding_numbers'] },
    { key: 'dkt-numbers', permissions: ['dkt_numbers'] },
    { key: 'dt-ratio-statistics', permissions: ['statistics_dt_calls_statistics'] },
    { key: 'ct3-ratio-statistics', permissions: ['statistics_show_numbers'] },
    { key: 'sip-lines', permissions: ['routing_mera_sip'] },
    { key: 'sip-trunks', permissions: ['sip_trunks'] },
    { key: 'charges-compare', permissions: ['charges_compare'] },
    { key: 'counteragents', permissions: ['counteragents'] },
    { key: 'routesstatistics', permissions: ['routing_reports'] },
    { key: 'contract-template-call', permissions: ['contract_template_call'] },
    { key: 'force', permissions: ['routing_forces', 'routing_client_forces'] },
    { key: 'price-lists', permissions: ['price_lists'] },
    { key: 'price-lists-analysis', permissions: ['routing_price_lists'] },
    { key: 'price-lists-compare', permissions: ['price_lists_compare'] },
    { key: 'zones', permissions: ['zones'] },
    { key: 'counters', permissions: ['counters'] },
    { key: 'number-capacity-counters', permissions: ['number_capacity_counters'] },
    { key: 'promo-actions', permissions: ['promo_actions'] },
    { key: 'promo-codes', permissions: ['promo_codes'] },
    { key: 'nets', permissions: ['nets'] },
    { key: 'mtt', permissions: ['mtt'] },
    { key: 'locations', permissions: ['locations'] },
    { key: 'number-type-prefixes', permissions: ['number_type_prefixes'] },
    { key: 'region-prefixes', permissions: ['region_prefixes'] },
    { key: 'settings', permissions: [] },
    { key: 'event-resending', permissions: ['apps_management_resend_crm_events'] },
    { key: 'feature-flags', permissions: ['feature_flags'] },
    { key: 'international-customer-details/:id/:mode', permissions: ['apps_management_apps_international_customer_details'] },
    { key: 'invoices', permissions: ['invoices'] },
    { key: 'service-addresses', permissions: ['service_addresses'] },
    { key: 'partners', permissions: ['partners_management'] },
    { key: 'service-addresses', permissions: ['service_addresses'] },
    { key: 'partners', permissions: ['partners_management'] },
    { key: 'counteragent', permissions: ['requisites'] },
    { key: 'bank-requisites/:counteragent_id', permissions: ['requisites'] },
    { key: 'bank-requisites/:counteragent_id/history/:bank_requisite_id', permissions: ['requisites'] },
    { key: 'bank-requisites/:counteragent_id/edit/:bank_requisite_id', permissions: ['requisites'] },
    { key: 'counteragent-requisites/:counteragent_id', permissions: ['requisites'] },
    { key: 'counteragent-requisites/:counteragent_id/history/:counteragent_requisite_id', permissions: ['requisites'] },
    { key: 'counteragent-requisites/:counteragent_id/edit/:counteragent_requisite_id', permissions: ['requisites'] },
    { key: 'app-cdr-out-prefixes', permissions: ['app_cdr_out_prefixes'] },
    { key: 'waba-accounts', permissions: ['waba_accounts'] },
    { key: 'not-finished-call-sessions', permissions: ['not_finished_call_sessions'] },
    { key: 'novofon-tariff-plans', permissions: ['tariff_plans'], env: 'novofon' },
];
